<template>
  <div class="body-container">
    <top-bar></top-bar>
    <div class="main-container">
      <side-bar></side-bar>
      <plan-view></plan-view>
    </div>

    <passed-modules-modal></passed-modules-modal>
    <desired-modules-modal></desired-modules-modal>
    <desired-semester-modules-modal></desired-semester-modules-modal>
    <undesired-modules-modal></undesired-modules-modal>
    <report-modal></report-modal>
    <imprint-modal></imprint-modal>
    <privacy-modal></privacy-modal>
    <mobile-modal></mobile-modal>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import SideBar from './components/SideBar.vue';
import PlanView from './components/PlanView.vue';
import PassedModulesModal from "@/components/modals/PassedModulesModal.vue";
import DesiredModulesModal from "@/components/modals/DesiredModulesModal.vue";
import DesiredSemesterModulesModal from "@/components/modals/DesiredSemesterModulesModal.vue";
import UndesiredModulesModal from "@/components/modals/UndesiredModulesModal.vue";
import ReportModal from "@/components/modals/ReportModal.vue";
import ImprintModal from "@/components/modals/ImprintModal.vue";
import PrivacyModal from "@/components/modals/PrivacyModal.vue";
import MobileModal from "@/components/modals/MobileModal.vue";
import {mapActions} from "vuex";

export default {
  components: {
    TopBar,
    SideBar,
    PlanView,
    PassedModulesModal,
    DesiredModulesModal,
    DesiredSemesterModulesModal,
    UndesiredModulesModal,
    ReportModal,
    ImprintModal,
    PrivacyModal,
    MobileModal
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    ...mapActions(["openModal"]),
    isPhone() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      return width < 520 || height < 450;
    },
  },
  mounted() {
    if (this.isPhone()) {
      this.openModal('MobileModal')
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', 'Century Gothic', Helvetica, Verdana, Arial, Tahoma, Geneva, sans-serif;
  font-size: 0.4cm;
  line-height: 160%;
  font-weight: 400;
}

.body-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 1; /* lower z-index to make sure shadow of topbar is visible */
}

button,
input,
optgroup,
select,
textarea {
  /* normalize browser settings */
  font-family: inherit;
}

input[type="number"] {
  text-align: center;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

h2 {
  font-size: 1.2em;
  margin: 0;
}

</style>
