<template>
  <base-module-modal
      :added-modules="desiredSemesterModules"
      :hidden-modules="hiddenModules"
      :modal-name="this.$options.name"
      :unavailable-modules="notOfferedModules"
      :modal-title="modalTitle"
      :unavailable-reason="unavailableReason"
      @confirm-operation="updateDesiredSemesterModules"
      icon-modal="travel_explore"
      modal-subtitle="Selected modules will be pinned to this semester."
      text-hint="Modules not regularly offered are unavailable for selection. Instead, choose a 'Special Topic in ...'
      (math) or 'Module in ...' (other departments) placeholder if available."
  >
  </base-module-modal>

 
</template>

<script>

import BaseModuleModal from "@/components/modals/BaseModuleModal.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "DesiredSemesterModulesModal",
  components: {BaseModuleModal},
  computed: {
    ...mapState(["preferences", "currentModalSemester", "schedule", "offeredSemesters", "nextSemesters", "program"]),
    ...mapGetters(["mockModules"]),
    semesterDescription() {
      const semesterSchedule = this.schedule[this.currentModalSemester];
      return semesterSchedule ? semesterSchedule.semesterDescription : null;
    },
    hiddenModules() {
     // do not offer passed modules as well as undesired ones
      if (this.currentModalSemester !== 1) {
        return this.$store.state.preferences.passedModules
            .concat(this.$store.state.preferences.undesiredModules);
      }
      else {
         return this.$store.state.preferences.passedModules
            .concat(this.$store.state.preferences.undesiredModules).concat(this.mockModules);
      }
    },
    notOfferedModules() {
      return Object.entries(this.offeredSemesters)
          .filter(([, semesters]) => !semesters.includes(this.currentModalSemester))
          .map(([moduleId]) => moduleId);
    },
    desiredSemesterModules() {
      return Object.entries(this.preferences.desiredInSemModules)
          .filter(([, semester]) => this.nextSemesters.includes(semester))
          .map(([moduleId]) => moduleId)
          .filter(moduleId => !this.notOfferedModules.includes(moduleId));
    },
    modalTitle() {
      return "Explore module options in the " + this.semesterDescription?.toLowerCase() ?? "";
    },
    unavailableReason() {
      return "not offered in " + this.semesterDescription?.toLowerCase() ?? "";
    }
  },
  methods: {
    updateDesiredSemesterModules(markedModules) {
      const modulesToRemove = this.desiredSemesterModules.filter(module => !markedModules.includes(module))
      const modulesToAdd = markedModules.filter(module => !this.desiredSemesterModules.includes(module));

      modulesToRemove.forEach(module => {
        this.$store.commit("REMOVE_DESIRED_IN_SEM_MODULE", module);
      })
      modulesToAdd.forEach(module => {
        this.$store.commit("ADD_DESIRED_IN_SEM_MODULE", {module: module, semester: this.currentModalSemester});
      })
    }
  },
};
</script>
