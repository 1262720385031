<template>
  <base-modal
      :default-height="false"
      :modal-name="this.$options.name"
      icon-modal="admin_panel_settings"
      modal-title="Datenschutzerklärung"
  >
    <template #body>
      <div class="modal-print">
        <div class="section">
          <p>
            Für die Technische Universität München und für die TUM School of Computation, Information and Technology ist Datenschutz ein wichtiges Anliegen.
            Wir möchten, dass Sie wissen, wann wir welche Daten speichern und wie wir sie verwenden.
            Personenbezogene Daten werden auf diesem Web-Server der Universität nur im technisch notwendigen Umfang erhoben.
            Die Datenverarbeitung unterliegt den geltenden datenschutzrechtlichen Bestimmungen,
            insbesondere der Datenschutz-Grundverordnung (DSGVO),
            dem Bayerischen Datenschutzgesetz (BayDSG)
            und dem <a href="https://www.gesetze-im-internet.de/tmg/" target="_blank">Telemediengesetz (TMG)</a>.
          </p>
          <p>
            Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung und Verwendung personenbezogener Daten.
            Diese Informationen können jederzeit von unserer Webseite abgerufen werden.
          </p>
        </div>
        <div class="section">
          <h3>Technische Umsetzung</h3>
          <p>
            Die Webserver für den StudyPlanner werden von <a href="https://www.cit.tum.de/" target="_blank">TUM School of Computation, Information and Technology</a> betrieben.
          </p>
          <p>
            TUM School of Computation, Information and Technology
            Technische Universität München
            Arcisstraße 21
            80333 München
          </p>
        </div>

        <div class="section">
          <h3>Protokollierung</h3>
          <p>
            Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln Sie über Ihren Internetbrowser Daten an
            unseren Webserver. Die folgenden Daten werden während einer laufenden Verbindung zur Kommunikation zwischen
            Ihrem Internetbrowser und unserem Webserver temporär in einer Logdatei aufgezeichnet:
          </p>
          <ul>
            <li>IP-Adresse des anfragenden Rechners</li>
            <li>Datum und Uhrzeit des Zugriffs</li>
            <li>Name, URL und übertragene Datenmenge der abgerufenen Datei</li>
            <li>Zugriffsstatus (angeforderte Datei übertragen, nicht gefunden etc.)</li>
            <li>Erkennungsdaten des verwendeten Browser- und Betriebssystems (sofern vom anfragenden Webbrowser übermittelt)</li>
            <li>Webseite, von der aus der Zugriff erfolgte (sofern vom anfragenden Webbrowser übermittelt)</li>
          </ul>
          <p>
            Die Verarbeitung der Daten in dieser Logdatei geschieht wie folgt:
          </p>
          <ul>
            <li>Die Logeinträge werden kontinuierlich automatisch ausgewertet, um Angriffe auf die Webserver erkennen und entsprechend reagieren zu können.</li>
            <li>In Einzelfällen, z.B. bei gemeldeten Störungen, Fehlern und Sicherheitsvorfällen, erfolgt eine manuelle Analyse.</li>
            <li>Logeinträge, die älter als sieben Tage sind, werden durch Kürzung der IP-Adresse anonymisiert.</li>
          </ul>
          <p>
            Die in den Logeinträgen enthaltenen IP-Adressen werden nicht mit anderen Datenbeständen zusammengeführt,
            so dass kein Rückschluss auf einzelne Personen möglich ist.
          </p>
        </div>

        <div class="section">
          <h3>Aktive Komponenten</h3>
          <p>
            In diesem Webauftritt werden aktive Komponenten wie JavaScript verwendet.
            Diese Funktion kann durch die Einstellung Ihres Internetbrowsers von Ihnen abgeschaltet werden.
          </p>
        </div>

        <div class="section">
          <h3>Cookies</h3>
          <p>
            Um den Funktionsumfang unseres Internetangebotes zu erweitern und die Nutzung für Sie komfortabler zu gestalten, verwenden wir zum Teil so genannte „Cookies".
            Mit Hilfe dieser Cookies können beim Aufruf unserer Webseite Daten auf Ihrem Rechner gespeichert werden.
            Sie können das Speichern von Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass Cookies nur für die Dauer der jeweiligen Verbindung zum Internet gespeichert werden.
            Hierdurch könnte allerdings der Funktionsumfang unseres Angebotes eingeschränkt werden.
          </p>
        </div>

        <div class="section">
          <h3>Online-Formulare</h3>
          <p>
            Personenbezogene Daten, die Sie gegebenenfalls über Kontaktformulare und ähnliche Webseiten auf unseren Webservern an uns leiten
            werden nur mit Ihrem Einverständnis entgegengenommen und dienen ausschließlich dem in den Formularen genannten Zweck.
            Bitte beachten Sie die entsprechenden Hinweise auf den Formularen.
          </p>
          <p>
            In allen Formularen werden nur die personenbezogenen Daten erhoben, die unbedingt erforderlich sind.
            Die personenbezogenen Daten sind nur den jeweils zuständigen Mitarbeitern der TUM zugänglich.
            Ihre Daten werden nicht an Dritte weiteregegeben, es sei denn, dies ist im Formular explizit angegeben.
          </p>
          <p>
            Ihre personenbezogen Daten werden nach Zweckerfüllung gelöscht.
          </p>
          <p>
            Sie können jederzeit für die Zukunft Ihre Einwilligung widerrufen.
            Hierzu wenden Sie sich bitte an <a href="mailto:studyplanner@cit.tum.de">studyplanner@cit.tum.de</a>.
            Ihre Daten werden dann unverzüglich gelöscht.
          </p>
        </div>

        <div class="section">
          <h3>Elektronische Post (E-Mail)</h3>
          <p>
            Informationen, die Sie unverschlüsselt per Elektronische Post (E-Mail) an uns senden,
            können möglicherweise auf dem Übertragungsweg von Dritten gelesen werden.
            Wir können in der Regel auch Ihre Identität nicht überprüfen und wissen nicht, wer sich hinter einer E-Mail-Adresse verbirgt.
            Eine rechtssichere Kommunikation durch einfache E-Mail ist daher nicht gewährleistet.
            Wir setzen - wie viele E-Mail-Anbieter - Filter gegen unerwünschte Werbung („SPAM-Filter“) ein,
            die in seltenen Fällen auch normale E-Mails fälschlicherweise automatisch als unerwünschte Werbung einordnen und löschen.
            E-Mails, die schädigende Programme („Viren“) enthalten, werden von uns in jedem Fall automatisch gelöscht.
          </p>
        </div>

        <div class="section">
          <h3>Änderung der Datenschutzbestimmungen</h3>
          <p>
            Wir behalten uns vor, unsere Datenschutzbestimmungen gelegentlich anzupassen,
            damit sie stets den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen.
            Wir empfehlen Ihnen, diese Datenschutzbestimmungen regelmäßig zu lesen, um über den Schutz der von uns erfassten persönlichen Daten auf dem Laufenden zu bleiben.
            Durch die fortgesetzte Nutzung des Dienstes erklären Sie sich mit diesen Datenschutzbestimmungen und deren Aktualisierung einverstanden.
          </p>
        </div>

        <div class="section">
          <h3>Behördlicher Datenschutzbeauftragter</h3>
          <p>
            Den behördlichen Datenschutzbeauftragten der Technischen Universität München erreichen Sie per E-Mail unter <a href="mailto:beauftrager@datenschutz.tum.de">beauftragter(at)datenschutz.tum.de</a>
            oder über <a href="https://www.datenschutz.tum.de/" target="_blank">www.datenschutz.tum.de</a>
          </p>
        </div>

        <div class="section">
          <h3>Auskunft und Berichtigung</h3>
          <p>
            Sie haben das Recht, auf schriftlichen Antrag und unentgeltlich Auskunft über die personen&shy;bezogenen Daten zu erhalten, die über Sie gespeichert sind.
            Zusätzlich haben Sie das Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung.
          </p>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>

import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  name: "PrivacyModal",
  components: {BaseModal},
};
</script>

<style scoped>
.modal-print {
  overflow-y: auto;
  font-size: 90%;
  line-height: 1.5;
}

h3 {
  font-weight: bold;
  margin: 2em 0 0.5em 0;
}

p {
  margin-bottom: 10px;
}

.section:first-child p:first-child, .section:first-child h3:first-child {
  margin-top: 0;
}

a:link, a:visited {
  color: inherit;
}
</style>
