<template>
  <div class="topbar">
    <div v-if="config.disclaimer.display" class="disclaimer">
      <span id="beta">{{ config.disclaimer.header }}</span>&ensp;&ensp;
      <span>{{ config.disclaimer.text }} <a v-if="config.disclaimer.displayLink"
          class="clickable-text" @click="openModal('ReportModal')">{{ config.disclaimer.linkText }}</a></span>
    </div>
    <div class="topbar-content">
      <div class="topbar-intro">
        <h1>
          <span>StudyPlanner</span>
        </h1>
        <h2 class="subtitle">
          <span>TUM School of Computation, Information and Technology</span>
        </h2>
      </div>
      <div class="topbar-tools">
        <div class="tool tooltip-container" @click="openModal('PrivacyModal')">
          <span class="material-symbols-outlined">admin_panel_settings</span>
          <div class="tooltip-text bottom">Privacy</div>
        </div>
        <div class="tool tooltip-container" @click="openModal('ImprintModal')">
          <span class="material-symbols-outlined">fingerprint</span>
          <div class="tooltip-text bottom">Imprint</div>
        </div>
        <div :class="{ 'highlight': showError }" class="tool tooltip-container" @click="openModal('ReportModal')">
          <span class="material-symbols-outlined">feedback</span>
          <div class="tooltip-text bottom">Give feedback or report a problem</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {config} from '../../options.config.js';

export default {
  name: "TopBar",
  data() {
    return {
      isProduction: process.env.NODE_ENV === 'production'
    };
  },
  computed: {
    ...mapState(["hasSolution", "errorOccurred"]),
    ...mapGetters(["reqPrefsSet", "prefChangeDisabled", "addedPassedModules"]),
    showError() {
      return this.errorOccurred ||
          (!this.prefChangeDisabled && this.reqPrefsSet && this.addedPassedModules && !this.hasSolution);
    },
    config() {
      return config;
    },
  },
  methods: {
    ...mapActions(["openModal"]),
  }
};
</script>

<style scoped>
.topbar {
  width: 100vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2; /* higher z-index to make sure shadow of topbar is visible */
}

.disclaimer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  background-color: #D95117;
}

.disclaimer span {
  text-align: center;
  margin: 5px 0;
}

.clickable-text {
  cursor: pointer;
  text-decoration: underline;
}

#beta {
  font-size: 16px;
  font-weight: bold;
}

.topbar-content {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-intro {
  margin: 10px 20px;
}

.subtitle {
  color: #6A757E;
  font-weight: normal;
}

.topbar-tools {
  display: flex;
  margin: 10px 20px;
}

.tool {
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  color: #6A757E;
  transition: color 0.3s;
  cursor: pointer;
}

.tool:hover {
  color: #20252A;
}

.tool.highlight {
  color: #F3B295;
}

.tool.highlight:hover {
  color: #EA7237;
}
</style>
<script setup>
</script>