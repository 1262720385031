<template>
  <base-modal
      :default-height="false"
      :modal-name="this.$options.name"
      icon-modal="devices"
      modal-title="Mobile version not available"
  >
    <template #body>
      <div class="modal-print">
        <span>To give you the best user experience possible and access to all available features, please use this
          website on a desktop computer, laptop or tablet.</span>
      </div>
    </template>
  </base-modal>
</template>

<script>

import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  name: "MobileModal",
  components: {BaseModal},
};
</script>

<style scoped>
.modal-print {
  overflow-y: auto;
  font-size: 90%;
  line-height: 1.5;
}

</style>
