export function getBrowserInfo() {
    let ua = navigator.userAgent;
    let temp;
    let browserInfo = {
        name: "Unknown",
        version: "Unknown"
    };

    temp = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)
    if (temp) {
        if (/trident/i.test(temp[1])) {
            // for IE
            let rv = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return {name: "IE", version: (rv[1] || "")};
        }
        if (temp[1] === "Chrome") {
            // check if it's actually new Edge, masquerading as Chrome
            let temp2 = ua.match(/\b(OPR|Edge(?=\/))\/(\d+)/);
            if (temp2 != null) return {name: temp2[1].replace("OPR", "Opera"), version: temp2[2]};
        }
        browserInfo.name = temp[1];
        browserInfo.version = temp[2];
    } else {
        // for older browsers, like Netscape
        temp = ua.match(/version\/(\d+)/i)
        if (temp) {
            browserInfo.version = temp[1];
        }
    }

    return browserInfo;
}

export function sanitizeInput(input) {
    if (!input || typeof input !== "string") {
        return "";
    }

    // Limit the input to 5000 characters
    let sanitizedInput = input.slice(0, 5000);

    // Remove any script tags
    sanitizedInput = sanitizedInput.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

    // Escape special characters
    sanitizedInput = sanitizedInput
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#x27;');

    // Replace any encoded JavaScript "event:" strings (like event:alert)
    sanitizedInput = sanitizedInput.replace(/event:/gi, '');

    // Further security measures can be implemented depending on the exact use case.
    // Consider libraries like DOMPurify for a more comprehensive solution.

    return sanitizedInput;
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function getSemesterDifference(firstSemStr, secondSemStr) {
    /**
     * Returns the semester difference between the first and second argument semester strings.
     * Positive if the first semester is later than the second.
     */
    const pattern = /^[sw]\d{2}$/;  // Define the pattern to match semester strings

    [firstSemStr, secondSemStr].forEach(string => {
        if (!pattern.test(string)) {
            throw new Error(`Reference semester input format invalid: ${string}. Must adhere to the pattern ${pattern}`);
        }
    });

    const reference = secondSemStr[0] === 's'
        ? parseInt(secondSemStr.substring(1))
        : parseInt(secondSemStr.substring(1)) + 0.5;

    const semester = firstSemStr[0] === 's'
        ? parseInt(firstSemStr.substring(1))
        : parseInt(firstSemStr.substring(1)) + 0.5;

    return Math.round((semester - reference) * 2);
}