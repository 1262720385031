<template>
  <base-modal
      :modal-name="this.$options.name"
      :default-height="false"
      icon-modal="fingerprint"
      modal-title="Impressum"
  >
    <template #body>
      <div class="modal-print">
        <div class="section">
          <h3>Herausgeber</h3>
          <p>Technische Universität München<br/> Arcisstraße 21<br/> 80333 München<br/>
            <a href="mailto:studyplanner@cit.tum.de">studyplanner@cit.tum.de</a></p>
        </div>

        <div class="section">
          <h3>Rechtsform und Vertretung</h3>
          <p>Die Technische Universität München ist eine Körperschaft des Öffentlichen Rechts und staatliche Einrichtung
            (Art. 11 Abs. 1 BayHSchG). Sie wird gesetzlich vertreten durch den Präsidenten Prof. Dr. Thomas F.
            Hofmann.</p>
        </div>

        <div class="section">
          <h3>Zuständige Aufsichtsbehörde</h3>
          <p>Bayerisches Staatsministerium für Wissenschaft und Kunst</p>
        </div>

        <div class="section">
          <h3>Umsatzsteuer&shy;identifikations&shy;nummer</h3>
          <p>DE811193231 (gemäß § 27a Umsatzsteuergesetz)</p>
        </div>

        <div class="section">
          <h3>Inhaltliche Verantwortung und Technischer Ansprechpartner</h3>
          <p><strong>Prof. Dr. rer. nat.</strong>&nbsp;<strong>Andreas Wiese</strong><br> Boltzmannstraße 3<br> 85748
            Garching bei München</p>
          <p><a href="mailto:studyplanner@cit.tum.de">studyplanner@cit.tum.de</a></p>
          <p>Namentlich gekennzeichnete Internetseiten geben die Auffassungen und Erkenntnisse der genannten Personen
            wieder.</p>
        </div>

        <div class="section">
          <h3>Nutzungsbedingungen</h3>
          <p>Texte, Bilder, Grafiken sowie die Gestaltung dieses Webauftritts können dem Urheberrecht unterliegen. Nicht
            urheberrechtlich geschützt sind nach § 5 des Urheberrechtsgesetz (UrhG)</p>
          <ul>
            <li>Gesetze, Verordnungen, amtliche Erlasse und Bekanntmachungen sowie Entscheidungen und amtlich verfasste
              Leitsätze zu Entscheidungen und
            </li>
            <li>andere amtliche Werke, die im amtlichen Interesse zur allgemeinen Kenntnisnahme veröffentlicht worden
              sind, mit der Einschränkung, dass die Bestimmungen über Änderungsverbot und Quellenangabe in § 62 Abs. 1
              bis 3 und § 63 Abs. 1 und 2 UrhG entsprechend anzuwenden sind.
            </li>
          </ul>
          <p>Als Privatperson dürfen Sie urheberrechtlich geschütztes Material zum privaten und sonstigen eigenen
            Gebrauch im Rahmen des § 53 UrhG verwenden. Eine Vervielfältigung oder Verwendung urheberrechtlich
            geschützten Materials dieser Seiten oder Teilen davon in anderen elektronischen oder gedruckten
            Publikationen und deren Veröffentlichung ist nur mit unserer Einwilligung gestattet. Diese Einwilligung
            erteilen auf Anfrage die für den Inhalt Verantwortlichen. Der Nachdruck und die Auswertung von
            Pressemitteilungen und Reden sind mit Quellenangabe allgemein gestattet.</p>
          <p>Weiterhin können Texte, Bilder, Grafiken und sonstige Dateien ganz oder teilweise dem Urheberrecht
            Dritter unterliegen. Auch über das Bestehen möglicher Rechte Dritter geben Ihnen die für den Inhalt
            Verantwortlichen nähere Auskünfte.</p>
        </div>

        <div class="section">
          <h3>Haftungsausschluss</h3>
          <p>Alle in diesem Webauftritt bereitgestellten Informationen haben wir nach bestem Wissen und Gewissen
            erarbeitet und geprüft. Eine Gewähr für die jederzeitige Aktualität, Richtigkeit, Vollständigkeit und
            Verfügbarkeit der bereit gestellten Informationen können wir allerdings nicht übernehmen. Ein
            Vertragsverhältnis mit den Nutzern des Webauftritts kommt nicht zustande.</p>
          <p>Wir haften nicht für Schäden, die durch die Nutzung dieses Webauftritts entstehen. Dieser
            Haftungsausschluss gilt nicht, soweit die Vorschriften des § 839 BGB (Haftung bei Amtspflichtverletzung)
            einschlägig sind. Für etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch Schadsoftware
            oder der Installation oder Nutzung von Software verursacht werden, übernehmen wir keine Haftung.</p>
        </div>

        <div class="section">
          <h3>Links</h3>
          <p>Von unseren eigenen Inhalten sind Querverweise („Links“) auf die Webseiten anderer Anbieter zu
            unterscheiden. Durch diese Links ermöglichen wir lediglich den Zugang zur Nutzung fremder Inhalte nach § 8
            Telemediengesetz. Bei der erstmaligen Verknüpfung mit diesen Internetangeboten haben wir diese fremden
            Inhalte daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche
            Verantwortlichkeit ausgelöst wird. Wir können diese fremden Inhalte aber nicht ständig auf Veränderungen
            überprüfen und daher auch keine Verantwortung dafür übernehmen. Für illegale, fehlerhafte oder
            unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung von Informationen
            Dritter entstehen, haftet allein der jeweilige Anbieter der Seite.</p>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>

import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  name: "ImprintModal",
  components: {BaseModal},
};
</script>

<style scoped>
.modal-print {
  overflow-y: auto;
  font-size: 90%;
  line-height: 1.5;
}

h3 {
  font-weight: bold;
  margin: 2em 0 0.5em 0;
}

p {
  margin-bottom: 10px;
}

.section:first-child p:first-child, .section:first-child h3:first-child {
  margin-top: 0;
}

a:link, a:visited {
  color: inherit;
}
</style>
