export const config = {
    disclaimer: {
        display: true,
        header: "Warning: BETA version",
        text: "Correctness is not guaranteed. Provided plans confer no legal rights regarding studyability.",
        displayLink: true,
        linkText: "We value your feedback!",
    },
    startSemesters: [
        {id: "w24", name: "Winter term 2024/25"},
        {id: "s24", name: "Summer term 2024"},
        {id: "w23", name: "Winter term 2023/24"},
        {id: "s23", name: "Summer term 2023"},
        {id: "w22", name: "Winter term 2022/23"},
        {id: "s22", name: "Summer term 2022"},
        {id: "w21", name: "Winter term 2021/22"},
        {id: "s21", name: "Summer term 2021"},
        {id: "w20", name: "Winter term 2020/21"}
    ],
    currentSemester: "w24",
    degrees: [
        {
            id: "bsc",
            name: "Bachelor of Science",
            majors: [
                {
                    id: "mathematics",
                    name: "Mathematics",
                    fpsos: [
                        {
                            id: "19",
                            name: "FPSO 2019",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23"],
                            defaultForSemesters: ["w20", "s21", "w21", "s22", "w22", "s23"]
                        },
                        {
                            id: "23",
                            name: "FPSO 2023",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w23", "s24", "w24"]
                        }
                    ]
                }
            ]
        },
        {
            id: "msc",
            name: "Master of Science",
            majors: [
                {
                    id: "mathematics",
                    name: "Mathematics",
                    fpsos: [
                        {
                            id: "23",
                            name: "FPSO 2023",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "msce",
                    name: "Mathematics in Science and Engineering",
                    fpsos: [
                        {
                            id: "19",
                            name: "FPSO 2019",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "mds",
                    name: "Mathematics in Data Science",
                    fpsos: [
                        {
                            id: "23",
                            name: "FPSO 2023",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "mfas",
                    name: "Mathematical Finance and Actuarial Science",
                    fpsos: [
                        {
                            id: "23",
                            name: "FPSO 2023",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "mor",
                    name: "Mathematics in Operations Research",
                    fpsos: [
                        {
                            id: "14",
                            name: "FPSO 2014",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "data_engineering",
                    name: "Data Engineering and Analytics",
                    fpsos: [
                        {
                            id: "18",
                            name: "FPSO 2018",
                            validStartSemesters: ["w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w21", "s22", "w22", "s23", "w23", "s24", "w24"]
                        }
                    ]
                },
                {
                    id: "informatics_games_engineering",
                    name: "Informatics: Games Engineering",
                    fpsos: [
                        {
                            id: "18",
                            name: "FPSO 2018",
                            validStartSemesters: ["w20", "s21", "w21", "s22", "w22", "s23", "w23", "s24", "w24"],
                            defaultForSemesters: ["w20", "s21", "w21", "s22", "w22", "s23"]
                        },
                        {
                            id: "23",
                            name: "FPSO 2023",
                            validStartSemesters: ["w23", "s24", "w24"],
                            defaultForSemesters: ["w23", "s24", "w24"]
                        },

                    ]
                }
            ]
        }
    ]
};
