<template>
  <transition name="fade">
    <div v-if="isModalOpen" class="modal-overlay" @click="handleBackdropClick">
      <div ref="content" :class="{ 'default-height': defaultHeight }" class="modal-content">
        <div class="modal-header">
          <div class="modal-intro">
            <span class="material-symbols-outlined">{{ iconModal }}</span>
            <div class="modal-headline">
              <h1>{{ modalTitle }}</h1>
              <div v-if="modalSubtitle.length !== 0" class="modal-subtitle">
                {{ modalSubtitle }}
              </div>
            </div>
          </div>
          <div v-if="closingOffered" class="modal-controls">
            <span class="material-symbols-outlined" @click="closeModal">close</span>
          </div>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
          <div v-if="discardingOffered || confirmationRequired" class="modal-footer">
            <button v-if="discardingOffered" :disabled="discardingDisabled"
                    class="discard-button final-button"
                    @click="closeModal">
              {{ textDiscard }}
            </button>
            <button v-if="confirmationRequired && !confirmationFinished" :disabled="confirmationDisabled"
                    class="confirm-button final-button"
                    @click.stop="confirm">
              {{ textConfirm }}
            </button>
            <button v-else-if="confirmationRequired" :class="confirmationSucceeded ? 'success' : 'error'"
                    class="confirm-button final-button" disabled>
              {{ confirmationSucceeded ? textConfirmSuccess : textConfirmError }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "BaseModal",
  props: {
    modalName: String,
    iconModal: {
      type: String,
    },
    modalTitle: {
      type: String,
    },
    modalSubtitle: {
      type: String,
      default: ""
    },
    textDiscard: {
      type: String,
      default: "Discard"
    },
    textConfirm: {
      type: String,
      default: "Confirm"
    },
    textConfirmSuccess: {
      type: String,
      default: "Success!"
    },
    textConfirmError: {
      type: String,
      default: "Failed!"
    },
    defaultHeight: {
      type: Boolean,
      default: true
    },
    closingOffered: {
      type: Boolean,
      default: true
    },
    confirmationRequired: {
      type: Boolean,
      default: false
    },
    confirmationDisabled: {
      type: Boolean,
      default: false
    },
    discardingOffered: {
      type: Boolean,
      default: false
    },
    discardingDisabled: {
      type: Boolean,
      default: false
    },
    closeOnConfirm: {
      type: Boolean,
      default: true
    },
    confirmationFinished: {
      type: Boolean,
      default: false,
    },
    confirmationSucceeded: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(["currentModal"]),
    isModalOpen() {
      return this.currentModal === this.modalName;
    },
  },
  methods: {
    ...mapMutations(["CLOSE_MODAL"]),
    closeModal() {
      this.$emit("beforeClose");
      this.CLOSE_MODAL();
    },
    handleBackdropClick(event) {
      // Check if the clicked target is not the modal content itself
      if (this.closingOffered && this.$refs.content && !this.$refs.content.contains(event.target)) {
        this.closeModal();
      }
    },
    confirm() {
      this.$emit("confirm");
      if (this.closeOnConfirm) {
        this.closeModal();
      }
    }
  },
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  width: 80%;
  max-width: 650px;
  max-height: 535px;
  color: #555;
}

.modal-content.default-height {
  height: 80%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 25px 25px;
  border-bottom: 1px solid #bbb;
}

.modal-intro {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
  margin-right: 15px;
}

.modal-intro .material-symbols-outlined {
  font-size: 30px;
  color: #9ABCE4;
}

.modal-headline {
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 18px;
  margin: 5px 0;
}

.modal-subtitle {
  font-size: 80%;
  line-height: normal;
}

.modal-controls {
  display: flex;
  justify-content: flex-end;
}

.modal-controls .material-symbols-outlined {
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-controls .material-symbols-outlined:hover {
  background-color: #eee;
}

.modal-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 25px;
  overflow: auto;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.final-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.final-button:disabled {
  cursor: auto;
}

.confirm-button {
  color: white;
  border: 1px solid #9ABCE4;
  background-color: #9ABCE4;
}

.confirm-button.success:disabled {
  border-color: #9FBA36;
  background-color: #9FBA36;
}

.confirm-button.error:disabled {
  border-color: #D95117;
  background-color: #D95117;
}

.confirm-button:disabled {
  border-color: #DDE2E6;
  background-color: #DDE2E6;
}

.confirm-button:not(:disabled):hover {
  border-color: #5E94D4;
  background-color: #5E94D4;
}

.discard-button {
  color: #9ABCE4;
  border: 1px solid #9ABCE4;
  background-color: white;
}

.discard-button:disabled {
  color: #DDE2E6;
  border-color: #DDE2E6;
}

.discard-button:not(:disabled):hover {
  color: #5E94D4;
  border-color: #5E94D4;
}
</style>