<template>
  <base-modal
      :close-on-confirm="false"
      :discarding-disabled="!message.length || sending"
      :discarding-offered="true"
      :confirmation-disabled="!message.length || sending || sendAttempted"
      :confirmation-finished="this.sendAttempted"
      :confirmation-required="true"
      :confirmation-succeeded="this.sendSuccessful"
      :default-height="true"
      :modal-name="this.$options.name"
      :modal-subtitle="modalSubtitle"
      :text-confirm="textConfirm"
      :text-discard="textDiscard"
      @confirm="sendMessage"
      @before-close="beforeClose"
      icon-modal="feedback"
      modal-title="Give feedback or report a problem"
      text-confirm-error="Sending failed. Try again later."
      text-confirm-success="Message sent. Thanks!"
  >
    <template #body>
      <div class="report-input">
        <textarea v-model="message" :disabled="sending || sendAttempted" class="message" maxlength="5000"
                  placeholder="Type your message here..."></textarea>
        <div class="consent">
          <div class="consent-item">
            <input v-model="consentData" :disabled="sending || sendAttempted" type="checkbox">
            <span>Submit my preferences and obtained results for debugging purposes (recommended).</span>
          </div>
          <div class="consent-item">
            <input v-model="consentMetaData" :disabled="sending || sendAttempted" type="checkbox">
            <span>Submit my browser name and version for debugging purposes (recommended).</span>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>

import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  name: "ReportModal",
  components: {BaseModal},
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      message: "",
      consentData: false,
      consentMetaData: false,
      sending: false,
      sendAttempted: false,
      sendSuccessful: null,
    }
  },
  computed: {
    modalSubtitle() {
      return "StudyPlanner version: " + this.version;
    },
    textDiscard() {
      return this.sendAttempted ? "Close" : "Discard";
    },
    textConfirm() {
      return this.sending ? "Sending..." : "Send";
    },
  },
  methods: {
    beforeClose() {
      this.cleanModal();
    },
    cleanModal() {
      this.message = "";
      this.consentData = false;
      this.consentMetaData = false;
      this.sending = false;
      this.sendAttempted = false;
      this.sendSuccessful = false;
    },
    async sendMessage() {
      this.sending = true;
      try {
        this.sendSuccessful = await this.$store.dispatch("sendReport", {
          message: this.message,
          consentData: this.consentData,
          consentMetaData: this.consentMetaData
        });
      } catch (error) {
        this.sendSuccessful = false;
      } finally {
        this.sendAttempted = true;
        this.sending = false;
      }
    }
  }
};
</script>

<style scoped>
.report-input {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
}

.message {
  border: 1px dashed #bbb;
  border-radius: 4px;
  padding: 10px;
  overflow-y: auto;
  resize: none;
  line-height: 1.5;
  flex: 1;
}

.message:focus {
  outline: none;
}

.consent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.consent-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 90%;
  line-height: 1.5;
}

.consent-item input {
  margin-left: 0;
}

input[type="checkbox"] {
  cursor: pointer;
}

</style>
